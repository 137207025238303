import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Row,
  Col,
  Card,
  Typography,
  Collapse,
  Spin,
  message,
  Radio,
  Space,
  Drawer,
  Button,
} from "antd";
import { Line, Chart } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import axios from "axios";
import moment from "moment";

import PageHeader from "../Layout/PageHeader";
import AnalysisFilters from "./AnalysisFilters";
import { CALENDAR_MONTHS } from "../../constants/dates";

ChartJS.register(...registerables);

const endOfThisMonth = moment().endOf("month");
const twoMonthsAgo = moment().subtract(2, "months").startOf("month");

const colors = [
  "magenta",
  "yellow",
  "green",
  "geekblue",
  "purple",
  "gold",
  "cyan",
  "lime",
  "green",
  "red",
];
const DIMENSIONS = {
  time: [
    { label: "Week", value: "week" },
    { label: "Month ", value: "month" },
  ],
};

// Convert weekly data points to monthly
const weeklyToMonthly = (weeklyStats, metrics) => {
  const groupedByMonth = Object.values(
    _(weeklyStats)
      .groupBy(stat => `${stat.year}${stat.month}`)
      .sortBy(group => weeklyStats.indexOf(group[0]))
      .value()
  );

  return groupedByMonth.map((stats) => {
    const monthlyStat = { year: stats[0].year, month: stats[0].month };

    metrics.forEach((metric) => {
      monthlyStat[metric] = stats.reduce(
        (total, stat) => total + stat[metric],
        0
      );
    });

    return monthlyStat;
  });
};

const Analysis = () => {
  const [filters, setFilters] = useState({
    startDate: twoMonthsAgo,
    endDate: endOfThisMonth,
  });
  const [dimension, setDimension] = useState({ time: "week" });
  const [drawerVisible, setDrawerVisible] = useState(false);

  const [weeklyUserStats, setWeeklyUserStats] = useState([]);
  const [monthlyUserStats, setMonthlyUserStats] = useState([]);
  const [weeklyUserChannelStats, setWeeklyUserChannelStats] = useState([]);
  const [weeklyTopupStats, setWeeklyTopupStats] = useState([]);
  const [overAllTopupAmount, setOverAllTopupAmount] = useState();
  const [loading, setLoading] = useState({
    user: false,
    userMonthly: false,
    userChannel: false,
    topup: false,
  });

  useEffect(() => {
    const dateParams = {
      startDate: filters.startDate?.startOf("month")?.toISOString(),
      endDate: filters.endDate?.endOf("month")?.toISOString(),
    };

    const fetchWeeklyUserStats = async () => {
      setLoading((prevLoading) => ({ ...prevLoading, user: true }));

      try {
        const res = await axios.get(`/api/analysis/weekly-user`, {
          params: { ...dateParams },
        });
        setWeeklyUserStats(res.data.data);
      } catch (error) {
        message.error(error.response?.data?.message || error.message, 0);
      }

      setLoading((prevLoading) => ({ ...prevLoading, user: false }));
    };

    const fetchMonthlyUserStats = async () => {
      setLoading((prevLoading) => ({ ...prevLoading, userMonthly: true }));

      try {
        const res = await axios.get(`/api/analysis/monthly-user`, {
          params: { ...dateParams },
        });
        setMonthlyUserStats(res.data.data);
      } catch (error) {
        message.error(error.response?.data?.message || error.message, 0);
      }

      setLoading((prevLoading) => ({ ...prevLoading, userMonthly: false }));
    };

    const fetchWeeklyUserChannelStats = async () => {
      setLoading((prevLoading) => ({ ...prevLoading, userChannel: true }));

      try {
        const res = await axios.get(`/api/analysis/weekly-user-channel`, {
          params: { ...dateParams },
        });
        setWeeklyUserChannelStats(res.data.data);
      } catch (error) {
        message.error(error.response?.data?.message || error.message, 0);
      }

      setLoading((prevLoading) => ({ ...prevLoading, userChannel: false }));
    };

    const fetchWeeklyTopupStats = async () => {
      setLoading((prevLoading) => ({ ...prevLoading, topup: true }));
      const res = await axios.get(`/api/analysis/weekly-topup`, {
        params: { ...dateParams },
      });
      setWeeklyTopupStats(res.data.data);
      setOverAllTopupAmount(res.data.overallTotalAmount);
      setLoading((prevLoading) => ({ ...prevLoading, topup: false }));
    };

    fetchWeeklyUserStats();
    fetchMonthlyUserStats();
    fetchWeeklyUserChannelStats();
    fetchWeeklyTopupStats();
  }, [filters.startDate, filters.endDate]);

  return (
    <React.Fragment>
      <PageHeader title="Analytics" childRoutes={[]} />

      <Collapse defaultActiveKey="filters">
        <Collapse.Panel
          header={`Filters (${filters.startDate.format(
            "MMM YYYY"
          )} - ${filters.endDate.format("MMM YYYY")})`}
          key="filters"
        >
          <Space>
            <AnalysisFilters
              filters={filters}
              onFiltersChange={(filters) => setFilters(filters)}
            />
            Time Dimension :
            <Radio.Group
              options={DIMENSIONS.time}
              value={dimension.time}
              onChange={(e) =>
                setDimension({ ...dimension, time: e.target.value })
              }
              optionType="button"
              buttonStyle="solid"
            />
          </Space>
        </Collapse.Panel>
      </Collapse>

      {dimension.time === "week" && (
        <>
          <Typography.Title level={4} className="mt-4">
            Users
          </Typography.Title>
          <Row gutter={[16, 16]}>
            <Col md={12}>
              <Card title="Signed Up & Activation User">
                {loading.user ? (
                  <Spin />
                ) : (
                  <Line
                    data={{
                      labels: weeklyUserStats.map((stat) => {
                        const monthName = CALENDAR_MONTHS[stat.month - 1]?.name;

                        return stat.week === 1
                          ? [
                              `W${stat.week}`,
                              monthName?.substring(0, 3),
                              stat.year,
                            ]
                          : [`W${stat.week}`];
                      }),
                      datasets: [
                        {
                          label: "Signed Up User Count",
                          backgroundColor: "rgb(255, 99, 132)",
                          borderColor: "rgb(255, 99, 132)",
                          data: weeklyUserStats.map(
                            (stat) => stat.signedUpCount
                          ),
                        },
                        {
                          label: "Activation User Count (Topup within 1 month)",
                          backgroundColor: "green",
                          borderColor: "green",
                          data: weeklyUserStats.map(
                            (stat) => stat.activationUserCount
                          ),
                        },
                      ],
                    }}
                    options={{
                      interaction: {
                        mode: "index",
                      },
                    }}
                  />
                )}
              </Card>
            </Col>

            <Col md={12}>
              <Card
                title={
                  <>
                    Weekly Active User (Topup at least once)
                    <Button
                      type="text"
                      danger
                      onClick={() => setDrawerVisible(true)}
                    >
                      (Why this appears larger in week dimensions?)
                    </Button>
                  </>
                }
              >
                {loading.user ? (
                  <Spin />
                ) : (
                  <Line
                    data={{
                      labels: weeklyUserStats.map((stat) => {
                        const monthName = CALENDAR_MONTHS[stat.month - 1]?.name;

                        return stat.week === 1
                          ? [
                              `W${stat.week}`,
                              monthName?.substring(0, 3),
                              stat.year,
                            ]
                          : [`W${stat.week}`];
                      }),
                      datasets: [
                        {
                          backgroundColor: "rgb(255, 99, 132)",
                          borderColor: "rgb(255, 99, 132)",
                          data: weeklyUserStats.map((stat) => stat.activeCount),
                        },
                      ],
                    }}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  />
                )}
              </Card>
            </Col>

            <Col md={24}>
              <Card title="Signed Up User by Channel">
                {loading.userChannel ? (
                  <Spin />
                ) : (
                  <Chart
                    type="line"
                    data={{
                      labels: weeklyUserStats.map((stat) => {
                        const monthName = CALENDAR_MONTHS[stat.month - 1]?.name;

                        return stat.week === 1
                          ? [
                              `W${stat.week}`,
                              monthName?.substring(0, 3),
                              stat.year,
                            ]
                          : [`W${stat.week}`];
                      }),
                      datasets: [
                        ...weeklyUserChannelStats.map(
                          (weeklyUserChannel, index) => {
                            return {
                              label: weeklyUserChannel.channel,
                              type: "bar",
                              yAxisID: "A",
                              maxBarThickness: 25,
                              data: weeklyUserChannel.data.map(
                                (stat) => stat.count
                              ),
                              borderColor: colors[index],
                              backgroundColor: colors[index],
                            };
                          }
                        ),
                        {
                          label: "TOTAL",
                          type: "line",
                          yAxisID: "B",
                          data: weeklyUserChannelStats?.[0]?.data.map(
                            (stat) => {
                              return weeklyUserChannelStats.reduce(
                                (total, weeklyUserChannel) => {
                                  const channelCount =
                                    weeklyUserChannel.data.find(
                                      (datum) =>
                                        datum.year === stat.year &&
                                        datum.month === stat.month &&
                                        datum.week === stat.week
                                    );
                                  return total + (channelCount?.count || 0);
                                },
                                0
                              );
                            }
                          ),
                          borderColor: "rgb(132, 99, 255)",
                          backgroundColor: "rgb(132, 99, 255)",
                        },
                      ],
                    }}
                    options={{
                      scales: {
                        A: {
                          type: "linear",
                          position: "left",
                          ticks: { color: "rgb(255, 99, 132)" },
                        },
                        B: {
                          title: { display: true, text: "TOTAL" },
                          type: "linear",
                          position: "right",
                          ticks: { color: "rgb(132, 99, 255)" },
                        },
                      },
                    }}
                  />
                )}
              </Card>
            </Col>
          </Row>

          <Typography.Title level={4} className="mt-4">
            Topup
          </Typography.Title>
          <Row gutter={16}>
            <Col md={12}>
              <Card
                title="Topup Count by Packages"
                extra={
                  <span
                    style={{
                      backgroundColor: "#f0f0f0",
                      padding: "5px 10px",
                      borderRadius: "4px",
                      fontWeight: "bold",
                      color: "#333",
                      border: "1px solid #d9d9d9",
                    }}
                  >
                    Total Topup: RM {overAllTopupAmount}
                  </span>
                }
              >
                {loading.topup ? (
                  <Spin />
                ) : (
                  <Line
                    data={{
                      labels: weeklyUserStats.map((stat) => {
                        const monthName = CALENDAR_MONTHS[stat.month - 1]?.name;

                        return stat.week === 1
                          ? [
                              `W${stat.week}`,
                              monthName?.substring(0, 3),
                              stat.year,
                            ]
                          : [`W${stat.week}`];
                      }),
                      datasets: weeklyTopupStats.map((weeklyTopup, index) => {
                        return {
                          label: weeklyTopup.topupAmount,
                          data: weeklyTopup.data.map((stat) => stat.count),
                          borderColor: colors[index],
                          backgroundColor: colors[index],
                        };
                      }),
                    }}
                    options={{
                      interaction: {
                        mode: "index",
                      },
                    }}
                  />
                )}
              </Card>
            </Col>

            <Col md={12}>
              <Card title="Activation Topup (Within 1 month after Signup)">
                {loading.user ? (
                  <Spin />
                ) : (
                  <Chart
                    type="line"
                    data={{
                      labels: weeklyUserStats.map((stat) => {
                        const monthName = CALENDAR_MONTHS[stat.month - 1]?.name;

                        return stat.week === 1
                          ? [
                              `W${stat.week}`,
                              monthName?.substring(0, 3),
                              stat.year,
                            ]
                          : [`W${stat.week}`];
                      }),
                      datasets: [
                        {
                          label: "Activation Topup Amount (RM)",
                          type: "line",
                          yAxisID: "A",
                          backgroundColor: "rgb(255, 99, 132)",
                          borderColor: "rgb(255, 99, 132)",
                          data: weeklyUserStats.map(
                            (stat) => stat.activationTopupAmount
                          ),
                        },
                        {
                          label: "Activation Topup Count",
                          type: "bar",
                          yAxisID: "B",
                          backgroundColor: "rgb(132, 99, 255)",
                          borderColor: "rgb(132, 99, 255)",
                          data: weeklyUserStats.map(
                            (stat) => stat.activationTopupCount
                          ),
                        },
                      ],
                    }}
                    options={{
                      scales: {
                        A: {
                          type: "linear",
                          position: "left",
                          ticks: { color: "rgb(255, 99, 132)" },
                        },
                        B: {
                          type: "linear",
                          position: "right",
                          ticks: { color: "rgb(132, 99, 255)" },
                        },
                      },
                    }}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </>
      )}

      {dimension.time === "month" && (
        <>
          <Typography.Title level={4} className="mt-4">
            Users
          </Typography.Title>
          <Row gutter={[16, 16]}>
            <Col md={12}>
              <Card title="Signed Up & Activation User">
                {loading.userMonthly ? (
                  <Spin />
                ) : (
                  <Line
                    data={{
                      labels: monthlyUserStats.map(
                        (stat) => `${stat.year}-${stat.month}`
                      ),
                      datasets: [
                        {
                          label: "Signed Up User Count",
                          backgroundColor: "rgb(255, 99, 132)",
                          borderColor: "rgb(255, 99, 132)",
                          data: monthlyUserStats.map(
                            (stat) => stat.signedUpCount
                          ),
                        },
                        {
                          label: "Activation User Count (Topup within 1 month)",
                          backgroundColor: "green",
                          borderColor: "green",
                          data: monthlyUserStats.map(
                            (stat) => stat.activationUserCount
                          ),
                        },
                      ],
                    }}
                    options={{
                      interaction: {
                        mode: "index",
                      },
                    }}
                  />
                )}
              </Card>
            </Col>

            <Col md={12}>
              <Card title="Monthly Active User (Topup at least once)">
                {loading.userMonthly ? (
                  <Spin />
                ) : (
                  <Line
                    data={{
                      labels: monthlyUserStats.map(
                        (stat) => `${stat.year}-${stat.month}`
                      ),
                      datasets: [
                        {
                          backgroundColor: "rgb(255, 99, 132)",
                          borderColor: "rgb(255, 99, 132)",
                          data: monthlyUserStats.map(
                            (stat) => stat.activeCount
                          ),
                        },
                      ],
                    }}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  />
                )}
              </Card>
            </Col>

            <Col md={24}>
              <Card title="Signed Up User by Channel">
                {loading.userChannel ? (
                  <Spin />
                ) : (
                  <Chart
                    type="line"
                    data={{
                      labels: monthlyUserStats.map(
                        (stat) => `${stat.year}-${stat.month}`
                      ),
                      datasets: [
                        ...weeklyUserChannelStats.map(
                          (weeklyUserChannel, index) => {
                            return {
                              label: weeklyUserChannel.channel,
                              type: "bar",
                              yAxisID: "A",
                              maxBarThickness: 25,
                              data: weeklyToMonthly(weeklyUserChannel.data, [
                                "count",
                              ]).map((stat) => stat.count),
                              borderColor: colors[index],
                              backgroundColor: colors[index],
                            };
                          }
                        ),
                        {
                          label: "TOTAL",
                          type: "line",
                          yAxisID: "B",
                          data: monthlyUserStats.map((stat) => {
                            return weeklyUserChannelStats.reduce(
                              (total, weeklyUserChannel) => {
                                const channelCount = weeklyToMonthly(
                                  weeklyUserChannel.data,
                                  ["count"]
                                ).find(
                                  (datum) =>
                                    datum.year === stat.year &&
                                    datum.month === stat.month
                                );
                                return total + (channelCount?.count || 0);
                              },
                              0
                            );
                          }),
                          borderColor: "rgb(132, 99, 255)",
                          backgroundColor: "rgb(132, 99, 255)",
                        },
                      ],
                    }}
                    options={{
                      scales: {
                        A: {
                          type: "linear",
                          position: "left",
                          ticks: { color: "rgb(255, 99, 132)" },
                        },
                        B: {
                          title: { display: true, text: "TOTAL" },
                          type: "linear",
                          position: "right",
                          ticks: { color: "rgb(132, 99, 255)" },
                        },
                      },
                    }}
                  />
                )}
              </Card>
            </Col>
          </Row>

          <Typography.Title level={4} className="mt-4">
            Topup
          </Typography.Title>
          <Row gutter={16}>
            <Col md={12}>
              <Card
                title="Topup Count by Packages"
                extra={
                  <span
                    style={{
                      backgroundColor: "#f0f0f0",
                      padding: "5px 10px",
                      borderRadius: "4px",
                      fontWeight: "bold",
                      color: "#333",
                      border: "1px solid #d9d9d9",
                    }}
                  >
                    Total Topup: RM {overAllTopupAmount}
                  </span>
                }
              >
                {loading.topup ? (
                  <Spin />
                ) : (
                  <Line
                    data={{
                      labels: monthlyUserStats.map(
                        (stat) => `${stat.year}-${stat.month}`
                      ),
                      datasets: weeklyTopupStats.map((weeklyTopup, index) => {
                        return {
                          label: weeklyTopup.topupAmount,
                          data: weeklyToMonthly(weeklyTopup.data, [
                            "count",
                          ]).map((stat) => stat.count),
                          borderColor: colors[index],
                          backgroundColor: colors[index],
                        };
                      }),
                    }}
                    options={{
                      interaction: {
                        mode: "index",
                      },
                    }}
                  />
                )}
              </Card>
            </Col>

            <Col md={12}>
              <Card title="Activation Topup (Within 1 month after Signup)">
                {loading.userMonthly ? (
                  <Spin />
                ) : (
                  <Chart
                    type="line"
                    data={{
                      labels: monthlyUserStats.map(
                        (stat) => `${stat.year}-${stat.month}`
                      ),
                      datasets: [
                        {
                          label: "Activation Topup Amount (RM)",
                          type: "line",
                          yAxisID: "A",
                          backgroundColor: "rgb(255, 99, 132)",
                          borderColor: "rgb(255, 99, 132)",
                          data: monthlyUserStats.map(
                            (stat) => stat.activationTopupAmount
                          ),
                        },
                        {
                          label: "Activation Topup Count",
                          type: "bar",
                          yAxisID: "B",
                          backgroundColor: "rgb(132, 99, 255)",
                          borderColor: "rgb(132, 99, 255)",
                          data: monthlyUserStats.map(
                            (stat) => stat.activationTopupCount
                          ),
                        },
                      ],
                    }}
                    options={{
                      scales: {
                        A: {
                          type: "linear",
                          position: "left",
                          ticks: { color: "rgb(255, 99, 132)" },
                        },
                        B: {
                          type: "linear",
                          position: "right",
                          ticks: { color: "rgb(132, 99, 255)" },
                        },
                      },
                    }}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </>
      )}

      <Drawer
        title="Monthly Active User (MAU) & Weekly Active User (WAU)"
        placement="right"
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        width={736}
      >
        <h3>MAU</h3>
        <p>
          <strong>
            The number of unique users that had at least one topup in that
            month.
          </strong>
        </p>

        <h3>WAU</h3>
        <p>
          <strong>
            The number of unique users that had at least one topup in that week.
          </strong>
        </p>

        <br />
        <h3>Example</h3>
        <p>
          During Month A, User A performed N times of Topup:
          <ul>
            <li>Week 1: 0</li>
            <li>Week 2: 1</li>
            <li>Week 3: 2</li>
            <li>Week 4: 3</li>
            <li>Week 5: 4</li>
          </ul>
        </p>

        <p>
          In MAU Chart, the contributed count is as follows:
          <ul>
            <li>Month A: 1</li>
          </ul>
        </p>

        <p>
          In WAU Chart, the contributed counts are as follows:
          <ul>
            <li>Week 1: 0</li>
            <li>Week 2: 1</li>
            <li>Week 3: 1</li>
            <li>Week 4: 1</li>
            <li>Week 5: 1</li>
          </ul>
        </p>
      </Drawer>
    </React.Fragment>
  );
};

export default Analysis;
